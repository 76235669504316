import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"

const Project = ({ data, location, pageContext }) => {
  const path = location.pathname
  const project = data.apiProject
  const {
    name,
    promotionLine,
    standardImage,
    showInsteadofAmount,
    whereDisplay,
    previous,
    next,
  } = project

  return (
    <Layout editPageId={project.id} path={path}>
      <SEO title={name} />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>{name}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="highlighted-cause">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <div className="section-heading">
                <h2 className="entry-title">{whereDisplay}</h2>
              </div>
              {/* .section-heading */}
              <div className="entry-content mt-4">
                <p>{promotionLine}</p>
              </div>
              {/* .entry-content */}
              <div className="fund-raised-goal mt-4">
                Goal: <strong>{showInsteadofAmount}</strong>
              </div>
              {/* .fund-raised */}
              <div className="entry-content mt-4">
                {project && "taxdeductable" in project && (
                  <p>
                    Project {project.taxdeductable ? " is" : " is not"} Tax
                    deductible
                  </p>
                )}
              </div>
              <div className="entry-footer mt-4">
                <Link to="/donate" className="btn gradient-bg">
                  Donate Now
                </Link>
              </div>
            </div>
            {/* .col */}
            <div className="col-12 col-lg-6 order-1 order-lg-2 mt-2">
              <img width="100%" src={standardImage} alt={name} />
            </div>
          </div>
          {project && (
            <div
              dangerouslySetInnerHTML={{ __html: project.details }}
              className="mt-3"
            ></div>
          )}
          {/* .row */}
          <div className="d-flex justify-content-around">
            {previous && (
              <Link className="" to={`/projects/${previous}`}>
                {"<"} Prev Project
              </Link>
            )}
            {next && (
              <Link className="" to={`/projects/${next}`}>
                Next Project {">"}
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Project

export const pageQuery = graphql`
query ProjectQuery($id: String!) {
  apiProject(id: {eq: $id}) {
    id
    name
    whereDisplay
    promotionLine
    showInsteadofAmount
    taxdeductable
    details
    standardImage
    thumbnailImage
    showInsteadofAmount
  }
}
`